<template>
  <div
    class="fixed xl:hidden h-17 border-t bg-white bottom-0 left-0 w-full z-50"
  >
    <div class="container">
      <ul v-if="currentPaper" class="items-center flex primary-menu pt-4">
        <template v-for="item in menuItems" :key="item.label">
          <li class="mr-5" :class="{ 'hidden md:flex': item.isHiddenOnMobile }">
            <NuxtLink
              :to="`${getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)}`"
              class="font-sans text-lg hover:text-blue"
              active-class="text-blue"
            >
              {{ $t(item.label) }}
            </NuxtLink>
          </li>
        </template>
        <li class="ml-auto">
          <NuxtLink :to="{ name: 'search' }">
            <SearchIcon class="w-6 h-6" />
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchIcon from '@/assets/icons/search.svg?component'

const indexStore = useIndexStore()
const route = useRoute()
const nuxtApp = useNuxtApp()

const primaryMenuItems = getPrimaryMenuItems()
const paperMenuItems = getPaperMenuItems()

// Computed to update automatically on route change
const menuItems = computed(() => {
  return route.params.paper ? paperMenuItems : primaryMenuItems
})

const routePrefix = computed(() => {
  return route.params.paper ? `/${route.params.paper}/` : '/'
})
const currentPaper = computed(() => indexStore.currentPaper)
</script>
