import type {
  JobAdDto,
  JobAdListDtoListResponseDto,
} from '~/typesAuto/jobshot/v1'

export const tagTypes = {
  jobOfTheDay: 1,
  highlightedJob: 2,
  promotedJob: 3,
} as const
export const GENITOR_PARTNER_ID = 1

export default () => {
  const { jobshotApiFetch } = useApiFetch()

  return {
    listJobAds(query?: {
      placementId?: string
      categoryId?: string
      locationId?: string
      pageSize?: number // default = 10
      random?: boolean
      partnerId?: number
    }) {
      return jobshotApiFetch<JobAdListDtoListResponseDto>('/api/v1/JobAds', {
        query,
      })
    },

    getJobAd(slug: string, isPreview?: boolean) {
      return jobshotApiFetch<JobAdDto>(`/api/v1/JobAds/${slug}`, {
        headers: isPreview
          ? {
              'Cache-Control': 'no-cache',
            }
          : undefined,
        credentials: isPreview ? 'include' : undefined,
      })
    },
  }
}
