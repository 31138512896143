import { decodeJwt } from 'jose'
import { parse } from 'cookie-es'
import type { FetchError } from 'ofetch'
import { appendResponseHeader, splitCookiesString } from 'h3'
import type { H3Event } from 'h3'
import type { JwtPayload } from '~/typesManual/jwt'

let refreshPromise: Promise<
  | {
      accessToken: string
      result: string
    }
  | {
      result: string
      data: unknown
    }
> | null = null

export function useAccessToken() {
  const event = useRequestEvent()
  const requestFetch = useRequestFetch()
  const siteConfig = useSiteConfig()

  const newAccessToken = useNewAccessToken()
  const newAutoLogin = useNewAutoLogin()

  const tokenCookie = useCookie('AccessToken', {
    readonly: true,
    secure: true,
    sameSite: 'none',
    path: '/',
  })

  const unvalidatedToken = computed(() => {
    if (import.meta.server && newAccessToken.value !== undefined) {
      return newAccessToken.value || undefined
    }

    return tokenCookie.value
  })

  const payload = computed(() => {
    if (!unvalidatedToken.value) return

    const payload = decodeJwt<JwtPayload>(unvalidatedToken.value)

    // Ignore tokens for other sites (ex. Hotshot)
    if (payload?.aud !== siteConfig.accessTokenAudience) {
      console.log(
        `Mismatched audience cannot be used: ${payload?.aud}, ${siteConfig.accessTokenAudience}`
      ) // mainly for developement debugging
      return
    }

    return payload
  })

  const token = computed(() => {
    if (!payload.value) return

    return unvalidatedToken.value
  })

  const isValid = () => {
    if (!payload.value) return false

    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000)

    // For debugging: +895 (refresh after 5s. 14m55s before expiration). For prod: +30 (refresh 30s before expiration)
    // return secondsSinceEpoch + 895 < payload.value.exp
    return secondsSinceEpoch + 30 < payload.value.exp
  }

  const refresh = async () => {
    // Inspired by https://nuxt.com/docs/getting-started/data-fetching#passing-headers-and-cookies
    const response = await $fetch<{ accessToken: string }>(
      '/api/auth/refresh-token',
      {
        method: 'POST',
        credentials: import.meta.server ? undefined : 'include', // Include cookies in the request on the client
        retry: 0,
        headers: import.meta.server
          ? useRequestHeaders(['cf-connecting-ip', 'cookie'])
          : undefined,
        onResponse: ({ response }) => {
          if (!import.meta.server || !event) return

          passCookiesToClient(event, response)

          // Save the new access token to the context so it can be used in the current SSR response
          const cookies = parse(response.headers.get('set-cookie') || '')
          newAccessToken.value = cookies.AccessToken
        },
      }
    ).catch(({ data }: FetchError) => ({ result: 'Refresh failed', data }))

    refreshCookie('AccessToken')

    return {
      result: 'Refresh success',
      ...response,
    }
  }

  // Dedupe the refresh function so there's only one refresh request at a time. All other requests will await the same promise.
  const dedupedRefresh = () => {
    if (!refreshPromise) {
      refreshPromise = refresh().finally(() => {
        refreshPromise = null
      })
    }

    return refreshPromise
  }

  const signOut = async () => {
    const response = await requestFetch('/api/auth/sign-out', {
      retry: 0,
      onResponse: ({ response }) => {
        if (!import.meta.server || !event) return
        passCookiesToClient(event, response)

        // Set the tokens to null in the context so they aren't available for the rest of the current SSR response
        newAccessToken.value = null
        newAutoLogin.value = null
      },
    })
    refreshCookie('AccessToken')
    return response
  }

  return {
    payload,
    token,
    unvalidatedToken,
    isValid,
    refresh: dedupedRefresh,
    signOut,
  }
}

// Transfer the set-cookie header from the API response to the current SSR response
export function passCookiesToClient(event: H3Event, response: Response) {
  const cookieString = response.headers.get('set-cookie')

  if (!cookieString) return

  const cookieStrings = splitCookiesString(cookieString)

  for (const cookie of cookieStrings) {
    appendResponseHeader(event, 'set-cookie', cookie)
  }
}

/**
 * On the server the cookie is only read at the start of the request, so we need a way to keep track of updates during the current SSR response.
 */
export function useNewAccessToken() {
  const event = useRequestEvent()
  if (import.meta.client || !event) {
    return ref<string | null | undefined>()
  }

  if (!('newAccessToken' in event.context)) {
    event.context.newAccessToken = ref<string | null | undefined>()
  }

  return event.context.newAccessToken
}
