export const ALERT_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
} as const
export type AlertType = (typeof ALERT_TYPE)[keyof typeof ALERT_TYPE]

export const useAlert = () => {
  const nuxtApp = useNuxtApp()
  const alertMessageRaw = ref<string | undefined>(undefined) // Untranslated message
  const alertMessage = computed(
    () =>
      alertMessageRaw.value ? nuxtApp.$t(alertMessageRaw.value) : undefined // Translated message
  )
  const alertType = ref<AlertType | undefined>(undefined)
  return {
    alertMessageRaw,
    alertMessage,
    alertType,
    setAlert: (message: string, type: AlertType = 'error') => {
      if (!Object.values(ALERT_TYPE).includes(type)) return

      alertMessageRaw.value = message
      alertType.value = type
    },
    clearAlert: () => {
      alertMessageRaw.value = undefined
      alertType.value = undefined
    },
  }
}
