<template>
  <div>
    <template v-if="type === 'slider'">
      <LayoutSwipeList class="mb-10">
        <SwiperSlide v-for="(item, index) in items" :key="index">
          <DecisionchainTeaser
            :key="index"
            :item="item"
            :index="index"
            type="slider"
          />
        </SwiperSlide>
      </LayoutSwipeList>
    </template>
    <template v-else>
      <template v-if="placement === 'side'">
        <CommonListLabel
          :label="label"
          :link="currentPaperUrl + '/' + $t('Routes.DecisionChain')"
        />
        <CommonDivider :dark="false" />
      </template>
      <LayoutPagedList v-if="placement === 'side' && Array.isArray(items)">
        <SwiperSlide
          v-for="(p, page) in Math.ceil(items.length / count)"
          :key="'page-' + page"
        >
          <DecisionchainTeaser
            v-for="item in items.slice(page * count, page * count + count)"
            :item="item"
            :placement="placement"
            class="mb-4"
          />
          <CommonDivider v-if="false" />
        </SwiperSlide>
      </LayoutPagedList>
      <ul v-else>
        <template v-for="(item, index) in items" :key="'li' + index">
          <li :class="index !== items.length - 1 ? 'mb-4' : ''">
            <DecisionchainTeaser
              :item="item"
              :index="index"
              :placement="placement"
            />
            <CommonDivider v-if="false" />
          </li>
        </template>
      </ul>
      <div v-if="showMore && moreToShow" class="flex justify-center">
        <CommonButton @click.native="loadMore">
          {{ $t('ShowMore') }}
        </CommonButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'

const nuxtApp = useNuxtApp()

const indexStore = useIndexStore()

const props = defineProps({
  count: {
    type: Number,
    default: 5,
  },
  pages: {
    type: Number,
    default: 3,
  },
  type: {
    type: String,
    default: 'default',
  },
  placement: {
    type: String,
    default: 'side',
  },
  label: {
    type: String,
    default: 'DecisionChain',
  },
  showMore: {
    type: Boolean,
    default: false,
  },
  offset: {
    type: Number,
    default: 0,
  },
})

let offset = props.offset

const { data: items } = await useAsyncData(async () => {
  const decisionchain = await nuxtApp.$api.content.decisionchain(
    indexStore.currentPaper ? indexStore.currentPaper.RecordId : '1',
    props.placement === 'side'
      ? props.count * props.pages + offset
      : props.count + offset
  )

  return decisionchain.filter((item) => item.UrlKey)
})

const currentPaperUrl = computed(() => {
  return indexStore.currentPaper?.RecordId === 1 ||
    !indexStore.currentPaper?.Url
    ? ''
    : `/${indexStore.currentPaper.Url}`
})

const moreToShow = computed(() => {
  if (Array.isArray(items)) {
    return items.length > offset + props.count
  } else {
    return true
  }
})

const loadMore = () => {
  offset += props.count
}
</script>
