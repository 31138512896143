<template>
  <div>
    <CommonListLabel
      :label="false ? 'Mit Mandag Morgen' : $t('MyAltinget')"
      :translate-label="false"
    />
    <CommonDivider
      :dark="false"
      :class="placement === 'side' ? '' : 'mb-3 md:mb-5'"
    />
    <div class="p-2 rounded-lg border flex items-center">
      <NuxtImg
        class="rounded-full shrink-0 bg-black w-15 h-15 mr-3 overflow-hidden object-cover object-center"
        width="64"
        loading="lazy"
        :src="userImage"
      />
      <div>
        <div class="list-title-xxxs !font-medium">
          {{ props.user.name }}
        </div>
        <div class="list-label !font-medium opacity-50 mt-1">
          <NuxtLink :to="{ name: 'account_profile' }">
            {{ $t('ShowMyProfile') }}
          </NuxtLink>
        </div>
      </div>
    </div>
    <template v-if="userHasReadingList">
      <div class="mb-2 mt-2 flex justify-between">
        <NuxtLink
          :to="{ name: 'saved_articles' }"
          class="label-m mt-2 opacity-50"
        >
          {{ $t('MySavedArticles') }}
        </NuxtLink>
        <NuxtLink :to="{ name: 'saved_articles' }">
          <CommonButton>
            {{ $t('ShowAll') }}
          </CommonButton>
        </NuxtLink>
      </div>
      <ListSavedArticles />
    </template>
  </div>
</template>

<script setup lang="ts">
import PersonFallbackIconUrl from '~/assets/icons/person-fallback.svg?url'

import type { TempUser } from '~/stores/user'

const props = withDefaults(
  defineProps<{ user: TempUser; placement?: string }>(),
  {
    placement: 'side',
  }
)

const userImage = computed(() => {
  if (!props.user.avatar || props.user.avatar === 'avatar_grey.svg') {
    return PersonFallbackIconUrl
  }
  return `https://legacy.altinget.dk/images/useravatars/${props.user.avatar}`
})

const userHasReadingList = computed(() => {
  return props.user.readingList.length > 0
})
</script>
