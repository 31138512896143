<template>
  <div
    class="audioplayer fixed w-full bg-white bg-opacity-90 border-t h-20 z-50"
    :class="{
      hidden: isHidden,
      'bottom-17 md:bottom-0': !false,
      'bottom-0': false,
    }"
  >
    <div
      class="container flex items-center justify-between gap-2 h-20 pt-4 md:pt-0"
    >
      <div class="items-center flex-1 pr-24 hidden md:flex">
        <img
          v-if="imageUrl"
          :src="imageUrl"
          class="rounded-lg w-14 h-14 object-cover"
        />
        <p class="list-title-xxxs ml-3">
          {{ title }}
        </p>
      </div>
      <button
        @click="togglePlaying"
        class="bg-red w-[42px] h-[42px] rounded-full items-center flex justify-center color-white"
      >
        <IconPause v-if="isPlaying" id="icon-pause" width="18" height="18" />
        <IconPlay v-else id="icon-play" width="18" height="18" />
      </button>
      <div
        class="w-full flex flex-col md:items-center justify-center md:pl-24 flex-1"
      >
        <div class="flex gap-x-2 flex-1 px-4 py-2 md:w-full">
          <span class="font-sans tabular-nums text-[9px] sm:text-[15px]">{{
            formatSecondsToMinuteSeconds(currentTime)
          }}</span>
          <input
            type="range"
            ref="seeker"
            v-model="seekerValue"
            max="100"
            @input="seek"
            class="flex-1"
          />
          <span
            class="font-sans sm:pr-4 text-[9px] sm:text-[15px] tabular-nums"
            >{{ formatSecondsToMinuteSeconds(duration) }}</span
          >
          <button @click="toggleMute">
            <CommonFontAwesomeIcon
              class="text-s w-4"
              :icon="
                isMuted ? 'fa-solid fa-volume-slash' : 'fa-solid fa-volume'
              "
            />
          </button>
          <audio
            @timeupdate="timeUpdate"
            @play="isPlaying = true"
            @pause="isPlaying = false"
            @ended="stop"
            @seeked="isPlaying = true"
            @waiting="isPlaying = false"
            @loadedmetadata="loadedMetaData"
            @canplaythrough="startPlaying"
            ref="player"
            :src="url"
            preload="metadata"
          ></audio>
        </div>
      </div>
      <button @click="stop" class="md:pl-8">
        <IconClose id="icon-close" />
      </button>
    </div>

    <p
      class="md:hidden absolute bottom-0 -translate-y-12 truncate transform opacity-50 label-s left-1/2 pb-1 -translate-x-1/2 w-1/2"
    >
      {{ title }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { FetchError } from 'ofetch'
import IconClose from '~/assets/icons/close.svg?component'
import IconPause from '~/assets/icons/pause.svg?component'
import IconPlay from '~/assets/icons/play.svg?component'
import { ElectionResults } from '~/typesManual/vaa_api/election'

const {
  url,
  imageUrl,
  title,
  isPlaying,
  isMuted,
  isHidden,
  stop,
  audioCurrentTime,
} = useAudioPlayer()

const player = ref<HTMLAudioElement>()
const seeker = ref<HTMLInputElement>()
const currentTime = ref<number>(0)
const duration = ref<number>(0)

const seekerValue = ref(0)

function toggleMute() {
  if (!player.value) return
  isMuted.value = !isMuted.value
  player.value.muted = isMuted.value
}

function seek() {
  if (!player.value) return
  const newTime = (seekerValue.value * player.value.duration) / 100
  player.value.currentTime = newTime
}

async function startPlaying() {
  if (!player.value) return
  try {
    await player.value.play()
  } catch (error) {
    if (error instanceof FetchError && error.response?.status === 404) {
      return [] as ElectionResults
    } else {
      console.error('player error', error)
      //TODO: Sentry?
    }
  }
}

// Watcher to react to isPlaying state changes
watch(isPlaying, async (newValue) => {
  if (!player.value) return
  if (newValue) {
    try {
      await player.value.play()
    } catch (error) {
      if (error instanceof FetchError && error.response?.status === 404) {
        return [] as ElectionResults
      } else {
        console.error('player error', error)
      }
    }
  } else {
    player.value.pause()
  }
})
watch(isMuted, (newValue) => {
  if (!player.value) return
  player.value.muted = newValue
})

async function togglePlaying() {
  if (!player.value) return
  if (!player.value.paused) {
    player.value.pause()
  } else {
    await startPlaying()
  }
}

const loadedMetaData = () => {
  if (!player.value) return
  duration.value = player.value.duration
}

function timeUpdate() {
  if (!player.value) return
  currentTime.value = player.value.currentTime
  audioCurrentTime.value = player.value.currentTime
  seekerValue.value = (player.value.currentTime / duration.value) * 100
  const bufferedSeconds =
    player.value.buffered.length && // Set to 0 if no buffered length to prevent IndexSizeError
    player.value.buffered.end(player.value.buffered.length - 1)
  const bufferedPercentage = (bufferedSeconds / player.value.duration) * 100
  seeker.value?.style.setProperty('--buffered-width', `${bufferedPercentage}%`)
  seeker.value?.style.setProperty(
    '--seek-before-width',
    `${(currentTime.value / duration.value) * 100}%`
  )
}

onMounted(() => {
  if (player.value) {
    player.value.load()
  }
})
</script>

<style lang="scss" scoped>
input[type='range']::-webkit-slider-runnable-track {
  @apply h-1 rounded-full;

  @if $isMM {
    background: linear-gradient(
      to right,
      #cad1d8 var(--buffered-width),
      #dadee3 var(--buffered-width)
    );
  } @else {
    background: linear-gradient(
      to right,
      #cad1d8 var(--buffered-width),
      #dadee3 var(--buffered-width)
    );
  }
}

input[type='range']::before {
  content: '';
  width: var(--seek-before-width);
  @apply h-1 top-3 absolute bg-blue left-0;
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none w-4 h-4 rounded-full bg-blue relative -mt-1.5;
}

input[type='range'] {
  background: transparent;
  @apply accent-blue appearance-none relative;
}
</style>
