<template>
  <div class="flex flex-col" v-if="sponsoredArticle">
    <NuxtLink
      :to="{
        name: 'sponsored_id',
        params: {
          id: sponsoredArticle.urlKey ?? sponsoredArticle.id,
        },
      }"
      class="@container flex justify-between w-full"
    >
      <div class="flex flex-col text-sm gap-2 w-full font-bold">
        <div class="opacity-50 mr-2">
          {{ $t('ads.nativeAds1').toLocaleUpperCase() }}
        </div>
        <div class="flex justify-between gap-2">
          <div class="flex flex-col gap-2">
            <h2 class="list-title-s" :class="{ 'text-2xl': !compact }">
              {{ sponsoredArticle.headline }}
            </h2>
            <div class="mr-2 flex items-center gap-1">
              <img
                v-if="sponsoredArticle.customWriterImageUrl"
                :src="sponsoredArticle.customWriterImageUrl"
                :height="24"
                class="h-6 object-cover rounded-sm"
              />
              <span class="text-blue">{{ sponsoredArticle.customWriter }}</span>
            </div>
          </div>
          <div
            class="overflow-hidden hidden md:flex w-21 h-12 grow-0 shrink-0 rounded-md md:w-45 md:h-25"
          >
            <NuxtImg
              v-if="!compact && getImageUrlFromArticle(sponsoredArticle)"
              :src="getImageUrlFromArticle(sponsoredArticle)"
              :width="300"
              :height="170"
              class="w-full h-auto image-loader"
            />
          </div>
        </div>
      </div>
    </NuxtLink>
    <CommonDivider />
  </div>
</template>
<script setup lang="ts">
import type { ArticleDto } from '~/typesAuto/apicore/v1'

const props = withDefaults(
  defineProps<{
    sponsoredArticleFromProp?: ArticleDto
    compact?: boolean
    top?: number
    offset?: number
  }>(),
  {
    top: 1,
    offset: 0,
  }
)
const indexStore = useIndexStore()
const currentPaperId = computed(() => indexStore.currentPaper?.RecordId ?? 1)
const nuxtApp = useNuxtApp()
const { data: sponsoredArticle } = await useAsyncData(
  `article-sponsored-teaser-top-${props.top}-offset-${props.offset}`,
  async () => {
    // do not fetch a sponsoredArticle if article is supplied through props
    if (props.sponsoredArticleFromProp) {
      return props.sponsoredArticleFromProp
    }

    const articles = await nuxtApp.$api.articles.getSponsoredArticles({
      paperId: currentPaperId.value,
      $top: props.top + props.offset,
      offset: props.offset,
    })

    if (articles.data.length > 0) {
      return articles.data[0]
    }
  }
)

function getImageUrlFromArticle(article: ArticleDto): string | undefined {
  if (!article.images) {
    return
  }
  return article.images[0]?.url ?? undefined
}
</script>
