import type { MetaObject } from '@nuxt/schema'
import type { SiteConfig } from '~/typesManual/config/site'

export type ExtraMetaContent = {
  type?: string
  description?: string
  author?: string
  image?: string
  url?: string
  section?: string
  publishedTime?: string
  modifiedTime?: string
  rawMetaTitle?: string
  rawMetaDescription?: string
}

export const createBasicHeadMetaData = (
  siteConfig: {
    [key: string]: unknown
  },
  requestUrl: URL
): MetaObject => {
  const typedSiteConfig = siteConfig as SiteConfig

  let imageToUse = ''
  if (typedSiteConfig.identifier === 'mm_dk') {
    imageToUse = `${requestUrl.origin}/defaultOgImageMm.png`
  } else {
    imageToUse = `${requestUrl.origin}/defaultOgImageAltinget.png`
  }

  const newHeadData: MetaObject = {
    title: typedSiteConfig.meta.defaultTitle,
    meta: [
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: `summary_large_image`,
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: `@${typedSiteConfig.twitter}`,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageToUse,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: typedSiteConfig.meta.defaultTitle,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageToUse,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: typedSiteConfig.meta.defaultTitle,
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: typedSiteConfig.localeCode,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `${requestUrl.href}`,
      },
    ],
  }

  return newHeadData
}

export const createHeadMetaData = (
  siteConfig: { [key: string]: unknown },
  requestUrl: URL,
  title?: string,
  extraMetaContent?: ExtraMetaContent,
  ignorePostFixTitle?: boolean
): MetaObject => {
  const typedSiteConfig = siteConfig as SiteConfig

  let imageToUse: string | undefined = undefined
  /* 
    If extraMetaContent has an image, use that
    If not, use the default image for the site
  */
  if (extraMetaContent?.image) {
    imageToUse = extraMetaContent.image
  } else if (typedSiteConfig.identifier === 'mm_dk') {
    imageToUse = `${requestUrl.origin}/defaultOgImageMm.png`
  } else {
    imageToUse = `${requestUrl.origin}/defaultOgImageAltinget.png`
  }

  const defaultTitle = typedSiteConfig.meta.defaultTitle
  let usedTitle = ''
  if (title && !ignorePostFixTitle) {
    usedTitle = `${title} - ${defaultTitle}`
  } else if (title) {
    usedTitle = title
  } else {
    usedTitle = defaultTitle
  }

  const metaTitle = extraMetaContent?.rawMetaTitle ?? usedTitle

  const newHeadData: MetaObject = {
    title: usedTitle,
    meta: [
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: `summary_large_image`,
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: `@${typedSiteConfig.twitter}`,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: metaTitle,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageToUse,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: metaTitle,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageToUse,
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: typedSiteConfig.localeCode,
      },
    ],
  }

  // ts fix
  if (!newHeadData.meta) {
    return {}
  }

  if (extraMetaContent) {
    if (extraMetaContent.description) {
      let metaDescription = extraMetaContent.description
      if (extraMetaContent.rawMetaDescription) {
        metaDescription = extraMetaContent.rawMetaDescription
      }

      newHeadData.meta.push(
        ...[
          {
            hid: 'description',
            property: 'description',
            content: extraMetaContent.description,
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: metaDescription,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: metaDescription,
          },
        ]
      )
    }
    if (extraMetaContent.type === 'article') {
      newHeadData.meta.push(
        ...[
          { hid: 'og:type', property: 'og:type', content: 'article' },
          {
            hid: 'article:section',
            name: 'article:section',
            content: extraMetaContent.section,
          },
          {
            hid: 'article:published_time',
            name: 'article:published_time',
            content: extraMetaContent.publishedTime,
          },
        ]
      )

      if (extraMetaContent.author) {
        newHeadData.meta.push({
          hid: 'article:author',
          name: 'article:author',
          content: extraMetaContent.author,
        })
      }

      if (extraMetaContent.modifiedTime) {
        newHeadData.meta.push({
          hid: 'article:modified_time',
          name: 'article:modified_time',
          content: extraMetaContent.modifiedTime,
        })
      }
    }
  } else {
    newHeadData.meta.push(
      ...[
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${requestUrl.href}`,
        },
      ]
    )
  }

  return newHeadData
}
