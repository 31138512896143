export const useAccountMenu = async () => {
  const siteConfig = useSiteConfig()
  const userStore = useUserStore()

  const { data: iterasPreAuth } = await useIterasPreAuth()
  const menuItems = computed(() => {
    const menuList = [
      {
        label: 'AccountAndPassword',
        route: { name: 'account_profile' },
        icon: 'fa-solid fa-user',
        description: 'account.profileDescription',
        isVisible: true,
      },
      {
        label: 'Newsletters',
        route: { name: 'account_newsletter' },
        icon: 'fa-solid fa-envelope',
        description: 'account.newsletterDesciption',
        isVisible: true,
      },
      {
        label: 'account.savedArticles',
        route: { name: 'saved_articles' },
        icon: 'fa-solid fa-bookmark',
        description: 'account.savedDescription',
        isVisible: true,
      },
      {
        label: 'account.loginAndSecurity',
        route: { name: 'account_loginsecurity' },
        icon: 'fa-solid fa-key',
        description: 'account.loginDescription',
        isVisible: userStore.hasLoginType('UsernamePassword'),
      },
      {
        label: 'account.decisionChain.Title',
        route: { name: 'account_decisionchains' },
        icon: 'fa-solid fa-arrows-to-dot',
        description: 'account.decisionChain.Description',
        isVisible: isSite('altinget_se'),
      },
      {
        label: 'Subscription',
        route: { name: 'account_subscription' },
        icon: 'fa-solid fa-credit-card',
        description: 'account.subscriptionDescription',
        isVisible:
          (userStore.user?.isAdmin && siteConfig.allowPurchase) ||
          (siteConfig.allowIterasSelfservice && iterasPreAuth.value),
      },
    ].filter((item) => item.isVisible)

    return menuList
  })

  return { menuItems }
}
