import { LocationQuery } from 'vue-router'
import type { EcommerceItem } from '~/typesManual/dataLayer'
export default defineNuxtPlugin({
  name: 'trackPurchaseComplete',
  dependsOn: ['vue-gtm'],
  setup: async () => {
    const nuxtApp = useNuxtApp()
    const userStore = useUserStore()
    const route = useRoute()
    const dataLayer = useDataLayer()
    const config = useRuntimeConfig()
    if (!userStore.isLoggedIn || !userStore.user) return

    const orderId =
      typeof route.query.orderid === 'string' ? route.query.orderid : ''

    if (orderId && userStore.user.userGuid) {
      // get order details
      try {
        const orderObject = await nuxtApp.$api.account.getSubscriptionOrder(
          userStore.user.userGuid,
          orderId
        )
        if (!orderObject.products)
          return console.error(`No products found in iteras order ${orderId}`)

        // Map order products to ecommerce
        const products = orderObject.products.map((product) => ({
          item_id: product.campaignId,
          item_name: product.subscribedTo?.map((p) => p.name).join(', '),
          item_variant: product.campaignName,
          item_category: 'Subscription',
          item_brand: config.public.site.name,
          quantity: product.quantity || 1,
          price: product.price || 0,
        }))
        // Check if marketing permission is given
        const marketingPermission = Boolean(orderObject.marketingPermission)

        // Calculate order value. Will be refactored when Iteras adds total_amount property to order api.(FE-1759)
        const orderValue = products.reduce((acc, curr) => acc + curr.price, 0)
        // Datalayer 'event': 'purchase',
        dataLayer.ecommerce.purchase(
          products as EcommerceItem[],
          orderObject.paymentMethod || '',
          orderObject.subscriptions || [],
          orderValue,
          marketingPermission
        )
      } catch (e) {
        console.error('Error fetching order details', e)
      }
      //remove orderId from query
      const newQuery: LocationQuery = { ...route.query }
      delete newQuery['orderid']

      await navigateTo({
        query: newQuery,
        replace: true,
      })
    }
  },
})
