// Provides marketing with more information about the user.
export default defineNuxtRouteMiddleware(async () => {
  if (!import.meta.server) return

  const userStore = useUserStore()

  if (!userStore.isLoggedIn || !userStore.user) return

  const alrowUser = {
    isLoggedIn: true,
    hasActiveSubscription: userStore.user.hasActiveSubscription,
  }

  useHead({
    script: [
      {
        key: 'alrow-user',
        tagPosition: 'head',
        innerHTML: `window.alrowUser = ${JSON.stringify(alrowUser)}`,
      },
    ],
  })
})
