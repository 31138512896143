<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AlrowTools />
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const route = useRoute()
const dataLayer = useDataLayer()
const config = useRuntimeConfig()

nuxtApp.hook('page:start', () => {
  dataLayer.basic.resetDatabay()

  const googleTagManager = window.google_tag_manager
  if (!googleTagManager) {
    return
  }

  const gtm = window.google_tag_manager[config.public.gtm.id]
  if (gtm) {
    gtm.dataLayer.reset()
  }
})

nuxtApp.hook('page:finish', () => {
  const userCodeData = dataLayer.basic.getUserCoreTrackingData()
  const routeCoreData = dataLayer.basic.getRouteCoreTrackingData()

  dataLayer.addToTracking(userCodeData)
  dataLayer.addToTracking(routeCoreData)
  dataLayer.basic.trackPageView()
})

// Stop NuxtLinks missing required params from crashing the page
onErrorCaptured((err, instance) => {
  if (err.message.startsWith('Missing required param')) {
    const linkTo = instance && 'to' in instance ? instance?.to : undefined

    if (import.meta.client) {
      nuxtApp.$sentry.captureException(err, { extra: { linkTo } })
    }

    return false
  }
})

/*
 * This code handles the dynamic display of ad labels based on events
 * emitted by an external ad script (`filledAd` and `unfilledAd`).
 *
 * - `AdEvent`: Defines the structure of ad-related events, specifying the ad container's ID (`divId`).
 * - `addAdEventListener`: Attaches global event listeners for `filledAd` and `unfilledAd` events.
 * - `removeAdEventListener`: Removes the global event listeners to prevent duplicate handlers.
 * - `handleAds`: Handles the logic for displaying or removing ad labels:
 *   - Adds a label after the ad container when `filledAd` is triggered.
 *   - Removes the label when `unfilledAd` is triggered.
 *   - If the document have an adnm element, remove the label for lb1.
 *
 * The event listeners are reset on every route change to ensure proper handling
 * of new ads and prevent stale event listeners from accumulating.
 * note: there is a workaround to remove the ad label when navigating away from the page
 * on ConceptAd.vue
 */
type AdEvent = {
  detail: {
    divId: string
  }
  type: 'filledAd' | 'unfilledAd'
}

const addAdEventListener = () => {
  window.document.addEventListener('unfilledAd', handleAds)
  window.document.addEventListener('filledAd', handleAds)
}
const removeAdEventListener = () => {
  window.document.removeEventListener('unfilledAd', handleAds)
  window.document.removeEventListener('filledAd', handleAds)
}

const handleAds = (event: unknown) => {
  const typedEvent = event as AdEvent
  const adContainer = document.getElementById(typedEvent.detail.divId)

  if (!adContainer) return

  const existingLabel = document.querySelector(
    `#ad-label-${typedEvent.detail.divId}`
  )

  if (typedEvent.type === 'filledAd') {
    if (typedEvent.detail.divId.includes('mob1')) return

    if (!existingLabel) {
      const adLabel = document.createElement('div')
      adLabel.dataset.adLabelElement
      adLabel.id = `ad-label-${typedEvent.detail.divId}` // Ensure unique ID for labels
      adLabel.className =
        'text-center text-black text-opacity-50 label-s block py-1'
      adLabel.textContent = nuxtApp.$t('Ad')
      adContainer.insertAdjacentElement('afterend', adLabel)
    }
  } else if (typedEvent.type === 'unfilledAd') {
    if (existingLabel) {
      existingLabel.remove()
    }
  }

  /*
   *  if the document has an adnm element, remove the label on lb1
   *   - This is a workaround, because the adnm element is added later in the process
   *     therefor we cannot use "adContainer.querySelector('.adnm-creative')"
   */
  const documentHasAdnm = document.querySelector('.adnm-creative')
  if (documentHasAdnm) {
    const adSelectors = [
      { adId: 'altinget-lb1', labelPrefix: 'ad-label-altinget-lb1' },
      { adId: 'altinget-mob1', labelPrefix: 'ad-label-altinget-mob1' },
    ]

    adSelectors.forEach(({ adId, labelPrefix }) => {
      const adElement = document.querySelector(`[data-ad-id="${adId}"]`)
      if (!adElement) return

      const adLabel = adElement.querySelector(`div[id^="${labelPrefix}"]`)
      if (adLabel) {
        adLabel.remove()
      }
    })
  }
}
if (import.meta.client) {
  onMounted(() => addAdEventListener())

  watch(
    () => route.fullPath,
    async () => {
      removeAdEventListener()
      await nextTick()
      addAdEventListener()
    }
  )
}
</script>
