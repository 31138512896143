<template>
  <div>
    <form
      ref="newsletterForm"
      class="mt-5 md:mt-8 ml-auto mr-auto"
      @submit.prevent="submit"
    >
      <div class="flex flex-col md:flex-row md:h-full">
        <input
          v-model="email"
          size="5"
          class="w-auto flex-grow py-2 px-4 md:py-4 md:px-6 border appearance-none article-label text-base font-medium placeholder-opacity-50 text-black leading-tight focus:outline-none focus:shadow-outline"
          :class="{
            'rounded-md': false,
            'rounded-full': !false,
          }"
          type="email"
          :placeholder="$t('Email')"
          required
        />
        <CommonButton
          :bg="false ? 'bg-black' : 'bg-blue'"
          text="text-white"
          border="border-none"
          size=""
          class="md:ml-2 md:h-auto h-9 mt-4 md:mt-0"
          :disabled="!newsletterForm?.checkValidity()"
        >
          {{ submitLabel }}
        </CommonButton>
      </div>
      <div class="flex flex-row items-start justify-start mt-6 mx-1">
        <CommonPermissionBox
          isNewsletter
          :permissionGiven="marketingPermissionGiven"
          @update:permission="updateMarketingPermission"
          :required="!isUserHasSubscription"
        />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { nwCookie } = useNwCookie()
const gtm = useGtm()
const userStore = useUserStore()

const props = withDefaults(
  defineProps<{
    buttonText?: string
    eventLabel?: string
    newsletter?: string | number
  }>(),
  {
    eventLabel: 'FreeNewsletterSignup',
    newsletter: 1, // 1 = the free frontpage newsletter.
  }
)

const emit = defineEmits(['signed-up'])

const email = ref('')
const marketingPermissionGiven = ref(false)
const newsletterForm = ref<HTMLFormElement>()

const submitLabel = computed(() => props.buttonText || nuxtApp.$t('Signup4'))
const isUserHasSubscription = computed(() => {
  return userStore?.user?.hasActiveSubscription
})

async function submit() {
  const { err } = await nuxtApp.$api.user.subscribeFreeNewsletter(
    email.value,
    FREE_NEWSLETTER_ID
  )

  if (err) {
    console.error(err.message, ': ', err.cause)
  } else {
    if (marketingPermissionGiven.value) {
      const { err } = await nuxtApp.$api.user.addMarketingPermission({
        email: email.value,
        permissionSource: 'Free Newsletter',
        permissionSourceUrl: window.location.href,
      })
      if (err) {
        console.error(err.message, ': ', err.cause)
      }
    }

    emit('signed-up')

    gtm?.trackEvent({
      event: 'formTracking',
      eventCategory: 'Form',
      eventAction: 'Form Completed',
      eventLabel: props.eventLabel,
    })

    nwCookie.value = true
  }
}

const updateMarketingPermission = (newValue: boolean) => {
  marketingPermissionGiven.value = newValue
}
</script>
