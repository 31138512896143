<template>
  <!-- if MM always show Callme -->
  <LayoutModal
    v-if="false && showCallMeConfirmation"
    @modal-close="emit('modal-close')"
  >
    <CommonConfirmation @modal-close="emit('modal-close')">
      <h3 class="font-sans text-3xl font-semibold">Vi kontakter dig snarest</h3>
      <p class="pt-3 mx-auto text-base leading-6 text-black text-opacity-50">
        Du bliver kontaktet en hverdag mellem 9-16. Kan vi ikke få fat på dig,
        sender vi dig en e-mail.
      </p>
    </CommonConfirmation>
  </LayoutModal>
  <LayoutModal
    v-else-if="false"
    title="CallMe"
    @modal-close="emit('modal-close')"
  >
    <AtsCallMe
      event-label="LeadSignup"
      @complete="showCallMeConfirmation = true"
      :requested-products="requestedProducts"
    />
  </LayoutModal>

  <!-- Always show StartTrial first -->
  <!-- Only show if user wants exactly one paper -->
  <LayoutModal
    v-else-if="!hasClickedStartTrial && papers.length === 1 && !skipStartTrial"
    @modal-close="emit('modal-close')"
  >
    <AtsStartTrial
      :paper="papers[0]"
      @clicked-start-trial="hasClickedStartTrial = true"
    />
  </LayoutModal>

  <!-- Login modal option -->
  <LayoutModal
    v-else-if="!userStore.isLoggedIn && showLogin === true"
    title="Login"
    @modal-close="emit('modal-close')"
  >
    <CommonLogin
      class="w-64 mx-auto"
      v-model:email="input.email"
      v-model:password="input.password"
      :in-modal="true"
      :allow-wayf="false"
    />
    <div class="mt-2 mb-2">
      <a
        class="text-sm font-sans font-medium text-blue underline cursor-pointer hover:no-underline"
        @click="showLogin = false"
      >
        {{ $t('CreateUser') }}
      </a>
    </div>
  </LayoutModal>

  <!-- User is NOT logged in -->
  <!-- Step 1 of 2 -->
  <LayoutModal
    v-else-if="!userStore.isLoggedIn"
    title="CreateUser"
    @modal-close="emit('modal-close')"
  >
    <LoginCreateUser
      v-model:email="input.email"
      v-model:password="input.password"
      @click-login="showLogin = true"
    >
      <div class="mt-2">
        <CommonButton
          size="xlarge"
          text="text-blue"
          bg-hover="hover:bg-gray-100"
          text-hover="false"
          class="w-full"
          @click.native="showLogin = true"
        >
          {{ $t('trialSignup.goToLogin') }}
        </CommonButton>
      </div>
    </LoginCreateUser>
  </LayoutModal>

  <!-- User DOES NOT have a client AND a name -->
  <LayoutModal
    v-else-if="userStore.user && (!userStore.hasClient || !userStore.hasName)"
    title="CreateUser"
    @modal-close="emit('modal-close')"
  >
    <!-- Step 2 of 2 -->
    <LoginCompleteUser
      v-model:email="input.email"
      v-model:password="input.password"
      :user="userStore.user"
      :papers="papers"
      :return-url="returnUrl"
      @modal-close="emit('modal-close')"
    />
  </LayoutModal>

  <LayoutModal
    v-else-if="!userStore.hasConfirmedWorkEmail"
    title="EmailNotConfirmed"
    @modal-close="emit('modal-close')"
  >
    <div class="flex flex-col items-center gap-2">
      <FormAlert :message="$t('activateemail')" type="error" />
      <CommonSendActivationEmailButton />
    </div>
  </LayoutModal>
  <!-- Show confirmation modal -->
  <LayoutModal
    v-else-if="
      userStore.hasLoginType('UsernamePassword', 'Sso') &&
      papers.length >= 1 &&
      userStore.hasSubscription(papers[0].RecordId)
    "
    @modal-close="emit('modal-close')"
  >
    <CommonConfirmation
      @modal-close="emit('modal-close')"
      data-testid="trial-confirmation-popup"
    >
      <i18n-t
        keypath="trialSignup.confirmAccessTitle"
        tag="h3"
        scope="global"
        class="font-sans text-2xl font-normal"
      >
        <span>{{
          false
            ? config.public.site.name
            : $formatList(
                papers.map(
                  (paper) => `${$config.public.site.name} ${paper.Name}`
                )
              )
        }}</span>
      </i18n-t>
      <p class="pt-3 mx-6 text-base leading-6 text-black">
        {{ $t('trialSignup.confirmAccessText') }}
      </p>
    </CommonConfirmation>
  </LayoutModal>

  <!-- User doesn't have access to the current paper -->
  <!-- Trial over call me logic -->
  <LayoutModal
    v-else-if="showCallMeConfirmation"
    @modal-close="emit('modal-close')"
  >
    <CommonConfirmation @modal-close="emit('modal-close')">
      <h3 class="font-sans text-3xl font-semibold">
        {{ $t('BecomeSubscriberStep4') }}
      </h3>
      <p class="pt-3 mx-auto text-base leading-6 text-black text-opacity-50">
        {{ $t('trialSignup.callMeFormDetails') }}
      </p>
    </CommonConfirmation>
  </LayoutModal>
  <LayoutModal
    v-else-if="showCallMe"
    title="CallMe"
    :has-back="true"
    @modal-back="showCallMe = false"
    @modal-close="emit('modal-close')"
  >
    <AtsCallMe @complete="showCallMeConfirmation = true" />
  </LayoutModal>

  <!-- Trial over -->
  <LayoutModal v-else-if="showTrialOver" @modal-close="emit('modal-close')">
    <AtsTrialOver
      :papers="papers"
      @call-me="showCallMe = true"
      @modal-close="emit('modal-close')"
    />
  </LayoutModal>

  <!-- Grant access -->
  <LayoutModal v-else @modal-close="emit('modal-close')">
    <AtsGrantAccess
      :papers="papers"
      @in-waiting-period="showTrialOver = true"
    />
  </LayoutModal>
</template>
<script setup lang="ts">
import type { ContentPaper } from '~/typesManual/content_api/paper'

const config = useRuntimeConfig()

const userStore = useUserStore()

const trialModalState = useTrialModalState()

const emit = defineEmits(['modal-close'])

withDefaults(
  defineProps<{
    papers: ContentPaper[]
    skipStartTrial?: boolean
    returnUrl?: string
  }>(),
  {
    skipStartTrial: false,
    returnUrl: undefined,
  }
)

const input = ref({
  email: userStore.user?.email ?? '',
  password: '',
})

const showLogin = ref(false)
const showTrialOver = ref(false)
const showCallMe = ref(false ? true : false)
const showCallMeConfirmation = ref(false)
const hasClickedStartTrial = ref(false)

const requestedProducts = trialModalState.value?.requestedProducts ?? []
</script>
