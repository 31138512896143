import type { FetchError } from 'ofetch'

export function useEmailFlow() {
  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()

  const confirmEmailJwt = async (
    confirmationToken: string,
    userGuid: string
  ) => {
    return await nuxtApp.$api.user.confirmEmailJwt(userGuid, confirmationToken)
  }

  /**
   * @deprecated
   */
  const confirmEmail = async (confirmationToken: string) => {
    // TODO this will break when or if the PATCH CALL do not return the user object.
    return await nuxtApp.$api.user.confirmEmail(confirmationToken)
  }

  /**
   * @deprecated
   */
  const sendConfirmationEmail = async (
    returnUrl: string,
    userId: number,
    emailType: 'ats' | 'standart',
    paperIds?: string
  ) => {
    return await nuxtApp.$api.user.sendConfirmationEmail(
      userId,
      returnUrl,
      emailType,
      true,
      false,
      paperIds
    )
  }

  const sendConfirmationEmailJwt = async (
    returnUrl: string,
    email: string,
    paperIds?: string
  ) => {
    return await nuxtApp.$api.user.sendConfirmationEmailJwt(
      `${config.public.site.domainfull}`,
      email,
      returnUrl,
      paperIds
    )
  }

  const sendAccessGrantedReceiptEmail = async (
    userId: number,
    receiptPapers: string
  ) => {
    return await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
      userId,
      receiptPapers
    )
  }

  const changePasswordJwt = async (
    userGuid: string,
    newPassword: string,
    passKey: string
  ) => {
    const userStore = useUserStore()

    const result = await userStore.loginPasswordEdit(
      userGuid,
      newPassword,
      passKey
    )

    if (result.status === 'success') {
      return createSuccessResponse()
    }

    return createErrorResponse('could not change password Jwt', result.error)
  }

  const changePassword = async (passkey: string, password: string) => {
    return await nuxtApp.$api.account.changePassword(passkey, password)
  }

  const sendPasswordResetEmail = async (email: string) => {
    const response = await nuxtApp.$api.account.sendPasswordResetEmail(email)

    if (response.err) {
      const error = response.err.cause as FetchError

      if (error.statusCode === 410) {
        return await nuxtApp.$api.account.sendPasswordResetEmailJwt(email)
      }
    }

    return response
  }

  return {
    confirmEmailJwt,
    confirmEmail,
    sendConfirmationEmail,
    sendConfirmationEmailJwt,
    sendAccessGrantedReceiptEmail,

    changePasswordJwt,
    changePassword,
    sendPasswordResetEmail,
  }
}
