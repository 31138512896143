<template>
  <CommonLoading v-if="loading" />
  <div v-else>
    <form
      class="w-64 ml-auto mr-auto grid gap-4"
      @submit.prevent="submit"
      id="form_trial_signup_create_user"
    >
      <p class="font-sans font-medium text-center text-sm">
        {{ $t('trialSignup.stepXOfY', { x: 1, y: 2 }) }}
      </p>

      <FormAlert
        :type="alertType"
        :message="
          alertMessageRaw &&
          [
            'atsLogin.error.disallowedDomainRiksdagen',
            'A user with that private or work email exists already',
          ].includes(alertMessageRaw)
            ? ''
            : alertMessage
        "
      >
        <template
          v-if="
            alertMessageRaw ===
            'A user with that private or work email exists already'
          "
        >
          {{ $t('user.errors.UserAlreadyExists') }}
          <span
            role="button"
            class="underline cursor-pointer text-blue"
            @click.prevent.stop="$emit('click-login')"
            >{{ $t('logInToContinue') }}</span
          >
          {{
            $t('user.errors.UseExistingAccount', {
              oppositeSiteName: false ? 'Altinget' : 'Mandag Morgen',
            })
          }}
          <NuxtLink
            external
            :to="{
              name: 'userforgotpassword',
            }"
            class="underline text-blue"
            >{{ $t('resetYourPasswordHere') }}</NuxtLink
          >
        </template>

        <i18n-t
          v-if="alertMessageRaw === 'atsLogin.error.disallowedDomainRiksdagen'"
          keypath="atsLogin.error.disallowedDomainRiksdagen"
          scope="global"
        >
          <template #loginRiksdagen>
            <a
              class="underline text-blue"
              href="/usercreate?email=9IlxiVgs0mzR9WMmQGjB7w%3D%3D"
              >HÄR</a
            >
          </template>
        </i18n-t>
      </FormAlert>

      <FormAlert v-if="isSSOEmail" type="info">
        <span
          class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
          v-html="$t('sso.YourEmailIsOnAnSSOClient', { url: ssoUrl })"
        ></span>
      </FormAlert>
      <FormInput
        :modelValue="email"
        type="email"
        required
        placeholder="enterEmail"
        @blur="checkSSO"
        @update:modelValue="$emit('update:email', $event)"
      />

      <FormPassword
        :modelValue="password"
        placeholder="choosePassword"
        :title="$t('passwordRequirements')"
        :allow-toggle="true"
        @update:modelValue="$emit('update:password', $event)"
      />

      <input
        type="submit"
        class="w-full cursor-pointer py-3 px-4 text-white article-label !font-medium"
        :value="$t('Next')"
        :class="
          false
            ? 'rounded-md bg-black transform translate-y-px'
            : 'rounded-full bg-blue hover:bg-denimblue'
        "
        @click="dataLayer.trial.createUser()"
      />
    </form>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { FetchError } from 'ofetch'
import { SSOProvider } from '~/typesManual/sso'
const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()
const dataLayer = useDataLayer()
const { sentry } = useSentry()

const userStore = useUserStore()

const props = defineProps<{
  email: string
  password: string
}>()

const emit = defineEmits<{
  (event: 'update:email', value: string): void
  (event: 'update:password', value: string): void
  (event: 'click-login'): void
}>()

const loading = ref(false)

async function submit() {
  clearAlert()

  if (isDisallowedAtsDomain(props.email)) {
    setAlert('atsLogin.error.disallowedDomainRiksdagen')
    return
  }

  loading.value = true

  const { err, data: user } = await nuxtApp.$api.user.createUser({
    workEmail: props.email,
    password: props.password,
  })

  if (err) {
    const error = err.cause as FetchError
    if (error.response?.status === 409) {
      setAlert('A user with that private or work email exists already')
    } else if (error.response?.status === 500) {
      setAlert('DefaultError')
      sentry?.captureException(
        new Error('Internal Server Error: ', {
          cause: error.response._data,
        })
      )
    } else {
      setAlert('DefaultError')
    }
    loading.value = false
    return
  }

  if (!user) {
    setAlert('DefaultError')
    loading.value = false
    return
  }

  const result = await userStore.loginUserJwt(user.workEmail, props.password)
  if (result.status === 'error') {
    if (result.code === 401) {
      setAlert('Wronglogin')
    } else if (result.code === 500) {
      setAlert('ServerError')
      sentry?.captureException(
        new Error('Internal Server Error: ', {
          cause: result.error,
        })
      )
    } else {
      setAlert('DefaultError')
      sentry?.captureException(
        new Error('Unknown Error: ', {
          cause: result.error,
        })
      )
    }
  }

  loading.value = false
}

// Single Sign-On checks
const ssoProvider = ref<SSOProvider | null>(null)
const isSSOEmail = computed(() => Boolean(ssoProvider.value))

const ssoUrl = computed(() => {
  if (import.meta.server || !ssoProvider.value) return ''

  const isIdentity = ssoProvider.value.providerName === 'IdentityEntraId'

  const url = new URL(
    isIdentity
      ? `${window.location.origin}/api/auth/sign-in-sso`
      : ssoProvider.value.providerUrl
  )
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})

async function checkSSO() {
  if (props.email && config.public.site.allowSingleSignOn) {
    ssoProvider.value = await nuxtApp.$api.user.getSsoProviderByEmail(
      props.email
    )
  } else {
    ssoProvider.value = null
  }
}

function isDisallowedAtsDomain(email: string) {
  return email.includes('@riksdagen.se')
}

// Error handling
const { setAlert, clearAlert, alertType, alertMessage, alertMessageRaw } =
  useAlert()
</script>
