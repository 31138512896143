<template>
  <div>
    <NuxtLink :to="qaLink">
      <div
        class="flex"
        :class="{
          'list-title-s !font-light flex items-center': placement === 'main',
          'list-label': placement === 'side',
        }"
      >
        <img
          v-if="teaserImage"
          class="w-12 h-12 mr-3 rounded-full shrink-0 grow-0 bg-center bg-cover"
          :class="{
            hidden: placement === 'main',
          }"
          :src="teaserImage"
        />
        <div
          v-else-if="placement === 'side'"
          class="w-12 h-12 mr-3 shrink-0 grow-0"
        ></div>
        <div class="flex-col">
          <template v-if="qaArticle.typeId === 4 && questionHasAnswer">
            <span class="whitespace-pre opacity-65">{{
              `${$t('qaRaadhus')} `
            }}</span>
            <span class="whitespace-pre">{{ `${$t('Answers')}:` }}</span>
            <img
              v-if="teaserImage"
              class="inline-block rounded-full shrink-0 grow-0 bg-center bg-cover"
              :class="{
                'w-6 h-6 mr-1': placement === 'side',
              }"
              :src="teaserImage"
            />
          </template>
          <template v-else-if="questionHasAnswer && qaArticle.aperson">
            <img
              class="inline-block w-8 h-8 mr-2 rounded-full shrink-0 grow-0 bg-center bg-cover"
              :src="teaserImage"
              :class="{
                hidden: placement === 'side',
              }"
            />
            <span class="text-red whitespace-pre">{{
              qaArticle.aperson.name
            }}</span
            ><span class="whitespace-pre">{{ ` ${$t('Answers')}:` }}</span>
          </template>
          <template v-else-if="questionHasAnswer">
            <span class="whitespace-pre opacity-65">{{
              `${$t('qaUdvalget')} `
            }}</span>
            <span class="whitespace-pre">{{ `${$t('Answers')}:` }}</span>
          </template>
          <template
            v-else-if="
              qaArticle.relQAQpersons?.[0] && qaArticle.relQAQpersons[0].person
            "
          >
            <img
              class="inline-block w-8 h-8 mr-2 rounded-full shrink-0 grow-0 bg-center bg-cover"
              :src="teaserImage"
              :class="{
                hidden: placement === 'side',
              }"
            />
            <span class="text-red whitespace-pre">{{
              qaArticle.relQAQpersons[0].person.name
            }}</span
            ><span class="whitespace-pre">{{ ` ${$t('Asks')}:` }}</span>
          </template>
          <template v-else>
            <span class="whitespace-pre opacity-65">{{
              qaArticle.typeId === 4
                ? `${$t('qaRaadhus')}`
                : `${$t('qaUdvalget')}`
            }}</span
            ><span class="whitespace-pre">{{ ` ${$t('Asks')}:` }}</span>
          </template>
          <div
            class="text-black"
            :class="{
              'list-title-m pt-2': placement === 'main',
              'list-title-xxxs pt-1': placement === 'side',
            }"
          >
            {{ qaArticle.qtitle }}
          </div>
          <div
            v-if="placement === 'main' && getTimeAsDate"
            class="text-red mt-3 article-label !font-medium opacity-50 flex items-center"
          >
            {{ getTimeAsDate }}
          </div>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type { QADto } from '~/typesAuto/apicore/v1'

const props = defineProps<{
  qaArticle: QADto
  placement: string
}>()

const qaLink = computed(() => {
  if (props.qaArticle.typeId === 4) {
    return {
      name: 'paper_raadhussvar',
      params: {
        id: props.qaArticle.id,
        paper: indexStore.currentPaperSlug,
      },
    }
  } else {
    return {
      name: indexStore.currentPaperSlug ? 'paper_qa' : 'qa',
      params: {
        id: props.qaArticle.id,
        paper: indexStore.currentPaperSlug,
      },
    }
  }
})

const indexStore = useIndexStore()

const questionHasAnswer = computed(() => {
  return Boolean(props.qaArticle.atext)
})

const teaserImage = computed(() => {
  if (questionHasAnswer.value) {
    return props.qaArticle.aperson?.imageUrl ?? ''
  } else {
    return props.qaArticle.relQAQpersons?.[0]?.person?.imageUrl ?? ''
  }
})

const getTimeAsDate = computed(() => {
  if (questionHasAnswer.value && props.qaArticle.adate) {
    return formatDate(new Date(props.qaArticle.adate), false)
  } else if (props.qaArticle.qdate) {
    return formatDate(new Date(props.qaArticle.qdate), false)
  }
})
</script>
