import type { ClientDto } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async createClient({
      name,
      jobPlace,
      telephone,
      zipcode,
      email,
    }: ClientDto) {
      return await apiCoreFetch<ClientDto>('clients', {
        method: 'POST',
        body: {
          name,
          jobPlace,
          telephone,
          zipcode,
          email,
        },
      })
    },
    async get(clientId: number) {
      return await apiCoreFetch<ClientDto>(`clients/${clientId}`)
    },
    async update(clientId: number, data: Record<string, unknown>) {
      await apiCoreFetch(`clients/${clientId}`, {
        method: 'PATCH',
        body: Object.keys(data).map((path) => ({
          op: 'add',
          path,
          value: data[path],
        })),
      })
    },
  }
}
